import { Component, OnInit, ChangeDetectionStrategy, Injector, Renderer2, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { LoginBackgroundImage, LoginDataService } from '../login/login-data.service';
import { LoginService } from '../login/login.service';
import { CoreComponentBase } from '@core/bases/core-component-base';
import { map, take, filter } from 'rxjs/operators';
import { TokenData } from '@core/auth/auth.models';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState, LocalStorageService, selectSettingsLanguage } from '@core/core.module';
import { actionSettingsChangeLanguage } from '@core/settings/settings.actions';
import { Language } from '@core/settings/settings.model';
import { environment as env } from '../../../environments/environment';
import { MitidService } from '@features/mitid/mitid.service';
declare const gapi: any;
declare var FB: any;

@Component({
  selector: 'kailo-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HealthComponent extends CoreComponentBase  {


  constructor(
    injector: Injector,
  
  ) {
    super(injector);
  }

  ngOnInit() {
   
  }




}
